<template>
  <b-container class="container-content">
    <div class="page-header">
      <h4 class="page-header-text">{{ $t('nav.my_favorite_maid') }}</h4>
    </div>
    <b-row>
      <b-col cols="12" md="6" lg="4" xl="3" v-for="(maid, index) in maids" :key="`maid-${index}`">
        <maid
          :detail="maid"
          :hasFavIcon="true"
          :isFavoriteMaid="maid.isFavorite"
          :isLoading="isUpdatingFavMaid"
        />
      </b-col>
    </b-row>
    <div v-if="totalMaids === 0" class="text-center">
      <p>
        {{ $t('my_favorite_maid.no_maids_selected') }}
      </p>
      <b-link :to="{ name: 'Maids' }">{{ $t('my_favorite_maid.view_all_maids') }}</b-link>
    </div>
  </b-container>
</template>
<script>
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import Maid from '../components/Maid'

const MaidStore = createNamespacedHelpers('maid')

@Component({
  components: { Maid },
  computed: {
    ...MaidStore.mapState(['favoriteMaid', 'recentlyUnFavMaids', 'isUpdatingFavMaid'])
  },
  methods: {
    ...MaidStore.mapActions(['fetchMyFavoriteMaids']),
    ...MaidStore.mapMutations(['resetRecentlyUnFaveMaids'])
  }
})
export default class MyMaidFavorite extends Vue {
  myFavoriteMaid = []
  async mounted() {
    await this.fetchMyFavoriteMaids()
  }
  beforeDestroy() {
    this.resetRecentlyUnFaveMaids()
  }

  get totalMaids() {
    return this.favoriteMaid.length + this.recentlyUnFavMaids.length
  }

  get maids() {
    const favMaids = [...this.favoriteMaid].map(maid => ({ ...maid, isFavorite: true }))
    const unFavMaids = [...this.recentlyUnFavMaids].map(maid => ({ ...maid, isFavorite: false }))
    return [...favMaids, ...unFavMaids].sort((a, b) => a.id - b.id)
  }
}
</script>
